@media (min-width: 37.5063rem) {
  .wrap-item {
    display: grid;
    grid-template-columns: 3.75rem 1fr;
  }
  .wrap-item:not(:last-of-type) {
    padding-bottom: 1.875rem;
  }
}
@media (max-width: 37.5rem) {
  .area-items {
    padding-left: 0.9375rem;
  }
  .wrap-item:not(:last-of-type) {
    padding-bottom: 1.875rem;
  }
}
