@media (min-width: 600.1008px) {
  .item-title-month {
    word-wrap: break-word;
    font-size: 16px;
    font-family: "NanumGothicExtraBold";
    padding: 20px 0.1008px 0.1008px 0.1008px;
  }
}
@media (max-width: 600px) {
  .item-title-month {
    word-wrap: break-word;
    font-size: 16px;
    font-family: "NanumGothicExtraBold";
    padding: 0.1008px 0.1008px 10px 0.1008px;
  }
}
