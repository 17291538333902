@media (min-width: 600.1008px) {
  .cont-title-year {
    word-wrap: break-word;
    font-size: 18px;
    font-family: "NanumGothicExtraBold";
    padding: 19px 0.1008px 0.1008px 0.0063rem;
  }
}
@media (max-width: 600px) {
  .cont-title-year {
    word-wrap: break-word;
    font-size: 18px;
    font-family: "NanumGothicExtraBold";
    padding: 0.1008px 0.1008px 16px 0.1008px;
  }
}
