.info-text {
  word-wrap: break-word;
  font-size: 0.8125rem;
  padding: 0.5938rem 0.8125rem 0.5938rem 0.8125rem;
}
@media (min-width: 25.0313rem) {
  .area-info-text {
    padding: 0.125rem;
  }
}
@media (max-width: 25rem) {
  .area-info-text {
    padding: 0.3125rem 0rem 0.3125rem 0.625rem;
  }
}
