.area-info-title {
  border: 0.125rem solid black;
  background-color: rgb(255, 245, 224);
  border-radius: 0.625rem;
}
.info-title {
  word-wrap: break-word;
  font-size: 0.9375rem;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
}
