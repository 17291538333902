@media (min-width: 37.5063rem) {
  .area-conts {
    margin-left: 0rem;
    padding: 0rem;
    list-style: none;
  }
  .wrap-cont {
    padding: 0rem 0rem 5rem 0rem;
    display: grid;
    grid-template-columns: 6.25rem 1fr;
  }
}
@media (max-width: 37.5rem) {
  .area-conts {
    margin-left: 0rem;
    padding: 0rem;
    list-style: none;
  }
  .wrap-cont {
    padding: 0rem 0rem 5rem 0rem;
  }
}
